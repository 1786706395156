



































































.sitemap {
    .first {

        width: 100%;

        a {
            color: #333;
            line-height: 3em;
            padding-bottom: .1rem;
            border-bottom: 1px dashed #b2b4b6;

            &:hover {
                border-bottom: 1px solid #333;
            }
        }

        >a {
            font-size: .22rem;
        }

        .second {
            display: flex;
            align-items: center;

            li {
                margin-left: .3rem;
            }

            a {
                font-size: .18rem;
            }
        }
    }

}
